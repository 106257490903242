<template>
  <div class="device_page">
    <advanced-form
      ref="newForm"
      :visible="showForm"
      :edit="editMode"
      @cancel="hideForm"
      @create="addNewForm"
    />
    <div class="table_ctrl">
      <a-space class="operator">
        <b style="font-size: 24px;">设备管理</b>
        <span>（ 共 {{ total }} 条数据 ）</span>
        <a-button @click="addNew" type="primary" v-if="isAdmin"
          ><a-icon type="plus-circle" theme="filled" />新增设备</a-button
        >
      </a-space>
      <a-space class="operator2">
        <!-- <a-switch default-checked @change="onSwitchChange" /> -->
        <!-- <a-button type="primary" icon="reload" @click="update" /> -->

        <region-select
          class="operator2_select"
          style="width: 220px"
          placeholder="设备地址"
          :value="regionselected"
          @change="onRegionChange"
        />
        <a-select
          class="operator2_select"
          style="width: 120px"
          placeholder="授权状态"
          allowClear
          @change="(e) => onSearchChange('lock', e)"
        >
          <a-select-option value="0">
            已授权
          </a-select-option>
          <a-select-option value="1">
            未授权
          </a-select-option>
        </a-select>
        <a-select
          class="operator2_select"
          style="width: 120px"
          placeholder="运行状态"
          allowClear
          @change="(e) => onSearchChange('status', e)"
        >
          <a-select-option value="0">
            运行
          </a-select-option>
          <a-select-option value="1">
            开机
          </a-select-option>
          <a-select-option value="2">
            故障
          </a-select-option>
        </a-select>
        <a-input-search
          class="operator2_search"
          v-model="keyword"
          placeholder="输入设备ID、设备型号"
          allow-clear
          @search="onSearch"
        >
          <a-button type="primary" icon="search" slot="enterButton">
            搜索
          </a-button>
        </a-input-search>
      </a-space>
    </div>
    <standard-table
      class="user_page_table"
      :columns="columns"
      :dataSource="dataSource"
      :pagination="{
        total: total,
        pageSize: pagination.pageSize,
      }"
      @clear="onClear"
      @change="onChange"
      @rowdblClick="rowClick"
      @selectedRowChange="onSelectChange"
    >
      <div slot="description" slot-scope="{ text }">
        {{ text }}
      </div>
      <div slot="itemsn" slot-scope="{ record }" style="height:21px;">
        <a
          class="user_page_table_title"
          href="javascript:;"
          :title="record.deviceSn"
          @click="viewRecord(record)"
          >{{ record.deviceSn }}</a
        >
      </div>
      <div slot="lock" slot-scope="{ record }">
        <!-- <span v-if="record.deviceInfos === null" style="color:brown"
          ><a-icon type="question-circle" theme="filled" /> 未知</span
        > -->
        <!-- <span v-else-if="record.deviceInfos.lock === '0'" style="color:#26cb59" -->
        <span v-if="record.permissionStatus === 1" style="color:#26cb59"
          ><a-icon type="safety-certificate" theme="filled" /> 已授权</span
        >
        <span v-else style="color:#fa5e3d"
          ><a-icon type="minus-circle" theme="filled" /> 未授权</span
        >
      </div>
      <div slot="status" slot-scope="{ record }">
        <span v-if="record.deviceInfos === null" style="color:brown">离线</span>
        <span v-else-if="record.deviceInfos.devSta === '0'" style="color:green">
          运行</span
        >
        <span
          v-else-if="record.deviceInfos.devSta === '1'"
          style="color:#00b5ff"
          >开机</span
        >
        <span v-else style="color:red">故障</span>
      </div>
      <div slot="action" slot-scope="{ record }">
        <a
          icon="search"
          @click="viewRecord(record)"
          style="margin-right: 8px"
          title="查看"
        >
          <a-icon type="zoom-in" />
        </a>
        <a-dropdown placement="bottomRight" v-if="isAdmin">
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            <a-icon type="bars" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item key="0">
              <a @click="editDevice(record)" style="margin-right: 8px">
                <a-icon type="form" /> 编辑
              </a>
            </a-menu-item>
            <a-menu-item key="1">
              <a @click="changePermiss(record)" style="margin-right: 8px">
                <a-icon type="safety-certificate" /> 更改授权
              </a>
            </a-menu-item>
            <a-menu-item key="2">
              <a @click="deleteRecord(record)" style="margin-right: 8px">
                <a-icon type="delete" /> 删除
              </a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <!-- <a-popconfirm
          title="Sure to del?"
          @confirm="() => deleteRecord(record.key)"
        >
          <a> <a-icon type="delete" /> </a>
        </a-popconfirm> -->
      </div>
      <template slot="statusTitle">
        <a-icon @click.native="onStatusTitleClick" type="info-circle" />
      </template>
    </standard-table>
    <popup-form
      :visible="visibleConfirm"
      :width="460"
      :maskClosable="false"
      title="确定删除？"
      oktext="确定"
      :destroyOnClose="true"
      @cancel="
        () => {
          this.visibleConfirm = false;
        }
      "
      @ok="
        () => {
          this.visibleConfirm = false;
          this.confirmDelete();
        }
      "
    >
      确定删除设备 <span style="color:#1b79d7;">{{ visibleConfirmSn }}</span> ？
    </popup-form>
    <change-permission ref="refcp" @create="getGoodList" />
  </div>
</template>

<script>
const columns = [
  {
    title: "设备ID",
    // dataIndex: "deviceSn",
    width: 130,
    ellipsis: true,
    scopedSlots: { customRender: "itemsn" },
  },
  {
    title: "设备名称",
    dataIndex: "deviceModel.deviceModelName",
    ellipsis: true,
  },
  {
    title: "设备型号",
    width: 110,
    dataIndex: "deviceModel.deviceModelType",
    ellipsis: true,
    searchAble: true,
  },
  {
    title: "出厂日期",
    width: 110,
    dataIndex: "dateOut",
  },
  {
    title: "设备地址",
    dataIndex: "address",
    ellipsis: true,
    searchAble: true,
  },
  // {
  //   title: "环境湿度(%)",
  //   width: 110,
  //   customRender: (text, record) => {
  //     if (record.deviceInfos !== null) {
  //       return record.deviceInfos.envHum;
  //     }
  //   },
  //   // scopedSlots: {customRender: 'wd'},
  // },
  // {
  //   title: "环境温度(℃)",
  //   width: 113,
  //   customRender: (text, record) => {
  //     if (record.deviceInfos !== null) {
  //       return record.deviceInfos.envTem;
  //     }
  //   },
  // },
  // {
  //   title: "发酵温度(℃)",
  //   width: 113,
  //   customRender: (text, record) => {
  //     if (record.deviceInfos !== null) {
  //       return record.deviceInfos.ferTem;
  //     }
  //   },
  // },
  {
    title: "总处理量(kg)",
    width: 113,
    customRender: (text, record) => {
      if (record.deviceInfos !== null) {
        // return record.deviceInfos.dlyIn;
        return Math.floor(Number(record.deviceInfos.ttlIn) + Number(record.deviceInfos.ttlPltWst));
      }
    },
  },
  {
    title: "总产出物(kg)",
    width: 113,
    customRender: (text, record) => {
      if (record.deviceInfos !== null) {
        // return record.deviceInfos.dlyOut;
        // return record.deviceInfos.ttlOut;
        // return Math.floor(Number(record.deviceInfos.ttlOut) * 10 + Number(record.deviceInfos.ttlPltWst) * 10)/10;
        return Math.floor(Number(record.deviceInfos.ttlOut) * 10)/10;
      }
    },
  },
  {
    title: "运行时间",
    width: 130,
    customRender: (text, record) => {
      if (record.deviceInfos !== null) {
        // return record.deviceInfos.dlyRt;
        return record.deviceInfos.dlyRt;
      }
    },
  },
  {
    title: "总消耗电量",
    width: 110,
    customRender: (text, record) => {
      if (record.deviceInfos !== null) {
        // return record.deviceInfos.dlykwh;
        return record.deviceInfos.ttlkwh;
      }
    },
  },
  {
    title: "故障信息",
    width: 110,
    ellipsis: true,
    customRender: (text, record) => {
      if (record.deviceErrs !== null) {
        // return record.deviceInfos.dlykwh;
        if (record.deviceErrs.length > 1) {
          // let str = `${record.deviceErrs.length}个故障：`;
          let str = ``;
          for (let i = 0; i < record.deviceErrs.length; i++) {
            str += record.deviceErrs[i].errContent + ", ";
          }
          if (str.length > 1) str = str.trim().slice(0,-1);
          return str;
        } else if (record.deviceErrs.length > 0) {
          return record.deviceErrs[0].errContent;
        } else {
          return "无";
        }
      } else {
        return "无";
      }
    },
  },
  {
    title: "是否授权",
    width: 98,
    scopedSlots: { customRender: "lock" },
    // customRender: (text, record) => {
    //   if (record.deviceInfos !== null) {
    //     if (record.deviceInfos.lock === "0") {
    //       return "授权";
    //     } else {
    //       return "停止";
    //     }
    //   } else {
    //     return "未知";
    //   }
    // },
  },
  {
    title: "状态",
    width: 60,
    scopedSlots: { customRender: "status" },
    // customRender: (text, record) => {
    //   if (record.deviceInfos !== null) {
    //     if (record.deviceInfos.devSta === "0") {
    //       return "运行";
    //     } else if (record.deviceInfos.devSta === "1") {
    //       return "运行";
    //     } else {
    //       return "故障";
    //     }
    //   } else {
    //     return "离线";
    //   }
    // },
  },
  {
    title: "操作",
    width: 80,
    scopedSlots: { customRender: "action" },
  },
];

// eslint-disable-next-line no-unused-vars
import { dataSource as ds } from "@/services";
import AdvancedForm from "./add/advance/AdvancedForm";
import RegionSelect from "@/pages/components/RegionSelect";
import StandardTable from "@/components/table/StandardTable";
import PopupForm from "@/pages/components/PopupForm";
import ChangePermission from "@/pages/components/ChangePermission";
import { mapGetters } from "vuex";
export default {
  name: "UserList",
  components: {
    StandardTable,
    AdvancedForm,
    PopupForm,
    RegionSelect,
    ChangePermission,
  },
  data() {
    return {
      loading: false,
      showForm: false,
      editMode: false,
      autoRefresh: true,
      formData: {},
      total: 0,
      conditions: {},

      advanced: true,
      showVModal: false,
      columns: columns,
      dataSource: [],
      selectedRows: [],
      pagination: {
        current: 1,
        pageSize: 13,
      },
      vmdata: undefined,
      rspvisible: {},
      newpsw: "",
      keyword: "",
      TickTimer: null,
      visibleConfirm: false,
      visibleConfirmSn: "",
      visibleConfirmId: "",
      regionselected: undefined,
      isAdmin: true,
    };
  },
  // authorize: {
  //   addNew: 'add',
  //   editDevice: 'edit',
  //   deleteRecord: 'delete',
  // },
  computed: {
    ...mapGetters("account", ["user", "roles"]),
  },
  mounted() {
    this.getGoodList();
    this.TickTimer = setInterval(() => {
      if (this.autoRefresh) this.getGoodList();
    }, 9700);
    // }, 9700)
    console.log("roles", this.roles);
    if (this.roles.id !== "admin") {
      this.isAdmin = false;
    }
  },
  beforeDestroy() {
    if (this.TickTimer) {
      clearInterval(this.TickTimer);
      this.TickTimer = null;
    }
  },
  activated() {
    console.log("device List activated");
    if (this.TickTimer === null) {
      this.TickTimer = setInterval(() => {
        if (this.autoRefresh) this.getGoodList();
      }, 9700);
    }
  },
  deactivated() {
    console.log("device List deactivated");
    if (this.TickTimer) {
      clearInterval(this.TickTimer);
      this.TickTimer = null;
    }
  },
  methods: {
    getGoodList() {
      this.loading = true;
      const { conditions } = this;
      // console.log('predeviceList', pageSize)
      ds.deviceList({
        pageIndex: this.pagination.current,
        pageSize: this.pagination.pageSize,
        keyword: this.keyword,
        ...conditions,
      })
        .then((result) => {
          console.log("deviceList", result);
          if (result.data.meta.success) {
            console.log("dataSource", result.data.data);
            // const {list, pageNum, pageSize, total} = result.data.data.list
            const { list, total } = result.data.data.list;
            // this.dataSource = list
            this.dataSource = list.map((item) => {
              return {
                ...item,
                key: item.deviceId,
              };
            });
            console.log("dataSourceList", this.dataSource);
            // this.pageIndex = pageNum
            // this.total = list.length;
            this.total = total;
            // this.pageSize = pageSize
            // this.total = result.data.data.list.total
            this.loading = false;
          } else {
            if (result.data.meta.code === 1006) {
              this.$message.error("获取设备列表出错：登录已过期，请重新登录");
              this.$router.push("/login");
            } else {
              this.$message.error("获取设备列表出错：" + result.data.meta.msg);
            }
            this.loading = false;
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
          this.loading = false;
        });
    },
    update() {
      this.getGoodList();
    },
    onSearch() {
      // this.$message.info("onSearch");
      this.getGoodList();
    },
    onSearch1(conditions, searchOptions) {
      console.log(searchOptions);
      this.pageIndex = 1;
      this.conditions = conditions;
      this.getGoodList();
    },
    toggleAdvanced() {
      this.advanced = !this.advanced;
    },
    remove() {
      this.dataSource = this.dataSource.filter(
        (item) =>
          this.selectedRows.findIndex((row) => row.key === item.key) === -1
      );
      this.selectedRows = [];
    },
    onClear() {
      this.$message.info("您清空了勾选的所有行");
    },
    onStatusTitleClick() {
      this.$message.info("你点击了状态栏表头");
    },
    onSwitchChange(e) {
      // this.$message.info('表格状态改变了')
      console.log("onSwitchChange", e);
      this.autoRefresh = e;
    },
    onChange(e) {
      // this.$message.info('表格状态改变了')
      console.log("onChange", e);
      const { current } = e;
      this.pagination.current = current;
      this.update();
    },
    onRegionChange(e) {
      // console.log("onRegionChange", e);
      this.regionselected = e;
      this.conditions["province"] = this.regionselected[0];
      this.conditions["city"] = this.regionselected[1];
      this.conditions["town"] = this.regionselected[2];
      console.log("onChange", this.conditions);
    },
    onSearchChange(flag, e) {
      // console.log("onSearchChange", e);
      this.conditions[flag] = +e;
    },
    onSelectChange() {
      this.$message.info("选中行改变了");
    },
    rowClick(e) {
      // this.$message.info("rowClick");
      // console.log("rowClick", e);
      this.viewRecord(e);
    },
    // addNew () {
    //   this.$router.push('/system/user/add')
    // },
    addNew() {
      this.editMode = false;
      this.showForm = true;
    },
    editDevice(record) {
      console.log("editDevice", record);
      this.editMode = true;
      this.showForm = true;
      this.$nextTick(() => {
        this.$refs.newForm.loadForm(record, null);
      });
    },
    hideForm() {
      this.showForm = false;
    },
    addNewForm() {
      console.log("newform");
      this.showForm = false;
      this.update();
    },
    viewRecord(e) {
      console.log("viewRecord", e);
      this.$router.push(`/devices/detail?sn=${e.deviceSn}&id=${e.key}`);
    },
    editRecord(e) {
      console.log(e);
      this.editMode = true;
      this.showForm = true;
      this.$refs.newForm.loadForm(e);
    },
    deleteRecord(e) {
      console.log(e);
      this.visibleConfirm = true;
      this.visibleConfirmSn = e.deviceSn;
      this.visibleConfirmId = e.deviceId;
    },
    confirmDelete() {
      ds.deleteDevice(this.visibleConfirmId).then((result) => {
        console.log("deleteDevice", result);
        if (result.data.meta.success) {
          this.$message.info(result.data.meta.msg);
          this.update();
        } else {
          this.$message.error(result.data.meta.msg);
          this.loading = false;
        }
      });
    },
    handleMenuClick(e) {
      if (e.key === "delete") {
        this.remove();
      }
    },
    changePermiss(record) {
      console.log("changePermiss", record);
      this.$refs.refcp.show(record);
    },
  },
};
</script>

<style lang="less" scoped>
.device_page {
  padding: 6px;
  margin-top: 7px;
  // margin-bottom: 20px;
  background: rgba(0, 20, 74, 0.8);
  border: 18px solid #027cc3;
  border-image: url(../../assets/img/card-b.png) 18 round;
  box-shadow: 0px 0px 25px 0px rgba(0, 138, 255, 0.4);
  color: #00effc;
  :deep(.ant-table) {
    color: #fff;
  }
  :deep(.ant-table-thead) {
    > tr {
      > th {
        background-color: #02215e;
        color: #00f9ff;
        border-bottom: 0px;
      }
    }
  }
  :deep(.ant-table-tbody) {
    > tr {
      > td {
        border-bottom: 1px solid #fff2;
      }
      &:hover {
        > td {
          background-color: #fff2;
        }
      }
    }
  }
  :deep(.ant-empty-normal) {
    // min-height: 190px;
    background-color: #fff0;
    color: #00f8fe;
  }
  :deep(.ant-table-placeholder) {
    background-color: #fff0;
    color: #00f9ff;
  }

  :deep(.ant-table-pagination) {
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      background-color: #30a7f8;
      border: 1px solid #027cc3;
    }
    .ant-pagination-item {
      background-color: #30a7f8;
      border: 1px solid #027cc3;
    }
    .ant-pagination-item a {
      color: #2226;
    }
    .ant-pagination-item a:hover {
      color: #2229;
    }
    .ant-pagination-item-active a {
      color: #000e;
    }
  }

  .table_ctrl {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    .operator2_search {
      width: 300px;
      :deep(.ant-input) {
        background-color: #021548;
        border-color: #0f4583;
        color: #fff;
      }
      :deep(.ant-input-clear-icon) {
        color: #fff6;
        &:hover {
          color: #fff;
        }
      }
    }
    .operator2_select {
      background-color: #021548;
      border-color: #0f4583;
      color: #fff;
      :deep(.ant-input) {
        background-color: #021548;
        border-color: #0f4583;
        color: #fff;
      }
      :deep(.ant-select-selection) {
        background-color: #021548;
        border-color: #0f4583;
        color: #fff;
      }
      :deep(.ant-select-selection__clear) {
        background-color: #0000;
        color: #fff6;
      }
      :deep(.ant-select-selection__clear:hover) {
        background-color: #0000;
        color: #fff;
      }
      :deep(.ant-cascader-picker-clear) {
        background-color: #0000;
        color: #fff6;
      }
      :deep(.ant-cascader-picker-clear:hover) {
        background-color: #0000;
        color: #fff;
      }
    }
    .operator {
      display: flex;
      align-items: center;
    }
  }
  .align_right {
    display: flex;
    flex-direction: row-reverse;
  }

  .search {
    margin-bottom: 54px;
  }
  .fold {
    width: calc(100% - 216px);
    display: inline-block;
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
  .user_page_table_title {
    display: inline-block;
    width: 110px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  // .user_page_table {
  //   :deep(.ant-table {
  //     min-height: 740px;
  //   }
  // }
  // :deep(.ant-empty-normal{
  //   min-height: 740px;
  // }
}
</style>
